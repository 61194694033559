import { Box, Button, Link, List, ListItem, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import { PageHeader } from 'elements/PageHeader';
import { SVGExternalLink } from 'icons';
import { MainWrapper } from 'elements/MainWrapper';
import { useResetKiosk } from 'hooks';

const MAX_TEXT_WIDTH = 584;

const NonAnonymousContent = ({ t }) => (
  <>
    <Stack
      alignItems={'center'}
      spacing={5}
      textAlign={'center'}
    >
      <Typography level={'h1'} fontSize={{ xs: 40, sm: 64 }}>
        {t('Your progress has been saved')}
      </Typography>
      <Typography level={'h2'} fontSize={24}>
        {t('You can now exit by closing this page.')}
      </Typography>
    </Stack>

    <div>
      <Typography sx={{ fontWeight: 500, mb: 1 }}>
        {t('How to access your survey again')}
      </Typography>
      <Typography>{t('If you have')}</Typography>
      <List marker={'disc'}>
        <ListItem>
          {t('an email or text message invitation, select the link in it')}
        </ListItem>
        <ListItem>
          {t('a passcode, go to the')}
          <Link
            href={'https://app.askyourteam.com/login'}
            endDecorator={<SVGExternalLink size={16} />}
            underline={'always'}
            sx={{ px: .5 }}
          >
            {t('login')}
          </Link>
          {t('screen to enter it.')}
        </ListItem>
      </List>
    </div>
  </>
);

const AnonymousContent = ({ t, is_kiosk_mode, browser_lock }) => (
  <Stack
    spacing={5}
    alignItems={'center'}
    textAlign={'center'}
  >
    <Typography level={'h1'} fontSize={{ xs: 40, sm: 64 }}>
      {t("We've sent you a link to finish your survey")}
    </Typography>
    <Typography level={'h2'} fontSize={24}>
      {is_kiosk_mode ? t('Select exit to end this session.', { ns: 'translation' }) : t('You can now exit by closing this page.')}
    </Typography>
    <Box textAlign={'left'}>
      <Typography sx={{ fontWeight: 500, mb: 2 }}>
        {t('How to access your survey again')}
      </Typography>
      <Typography sx={{ maxWidth: MAX_TEXT_WIDTH }}>
        {browser_lock ? t('Select the link in your email and it will automatically open.') : t('Select the link in your email. This link is unique to you and will let you continue with your survey.')}
      </Typography>
    </Box>
  </Stack>
);

export const ProgressSaved = () => {

  const {
    survey_uuid,
    anonymous,
    is_kiosk_mode,
    browser_lock
  } = useOutletContext();

  const { t } = useTranslation(['progress_saved_page', 'translation']);

  const { feedbackID } = useParams();

  const { onResetKiosk } = useResetKiosk({ feedbackID, survey_uuid });

  return (
    <>
      <PageHeader
        hideBackToWelcomePage
        hideSaveOrExit
      />
      <MainWrapper className={'app__centered'}>
        <Stack
          spacing={5}
          alignItems={'center'}
        >
          {anonymous
            ? <AnonymousContent t={t} is_kiosk_mode={is_kiosk_mode} browser_lock={browser_lock} />
            : <NonAnonymousContent t={t} />
          }
          {is_kiosk_mode && (
            <Button
              size={'lg'}
              onClick={onResetKiosk}
            >
              {t('Exit', { ns: 'translation' })}
            </Button>
          )}
        </Stack>
      </MainWrapper>
    </>
  );
};

export default ProgressSaved;
